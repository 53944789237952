#root {
  box-sizing: border-box;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 400px;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  border-right: 1px solid #ddd;
  z-index: 1;
}

.sidebar-content {
  padding: 10px;
}

.sidebar-items {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
}

.dropdown-container {
  background: #0eb6bd;
  padding: 10px;
}

.dropdown {
  margin-bottom: 5px;
}

.dropdown:last-of-type {
  margin-bottom: 0;
}

.ride-preview {
  display: block;
  background: #fff;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  max-height: 100vh;
  flex: 1;
}

.map {
  position: relative;
  height: 30vh;
}

.item {
  position: relative;
}

.graphs {
  display: flex;
  flex: 1;
  margin: 0 !important;
}

.graphs-content {
  flex: 1;
  overflow: auto;
}

.graphs-content > .list {
  flex: 1;
}

.graph-container {
  position: relative;
  user-select: none;
}

.graph-name {
  font-size: 12px;
  margin: 0;
  padding: 5px 10px;
  text-transform: capitalize;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: #0eb6bd;
  padding: 5px 10px;
}

.ride-preview .info-container {
  color: inherit;
  background: inherit;
  padding: 5px 0 0 0;
}

.ride-preview .info-container .info > div:last-of-type {
  margin: 0;
}

.info {
  display: flex;
}

.info div {
  margin-right: 10px;
}

.bold {
  font-weight: bold;
}

.info-panel {
  width: 360px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #ddd;
  overflow-y: auto;
  background: #eee;
}

.info-panel-cols {
  padding: 10px;
  column-count: 2;
}

.info-panel-header {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
}

.info-panel-item {
  font-size: 12px;
  padding: 0 0 5px 0;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: inherit !important;
}
